<template>
  <v-dialog v-model="dialog" min-width="86vh" max-width="90vh">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary lighten-2"
        icon
        v-bind="attrs"
        v-on="on"
        tooltip="hello"
      >
        <v-icon>mdi-map</v-icon>
      </v-btn>
    </template>

    <v-card>
      <GmapMap
        v-if="dialog"
        ref="gmap"
        :center="center"
        :zoom="0"
        map-type-id="terrain"
        :options="{ minZoom: 5, maxZoom: 19 }"
        style="height: 600px; width: 100%"
        class="elevation-11"
        @click="toggleInfoWindow"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="false"
          :draggable="false"
          :label="m.resource?.name ?? ''"
        />

        <GmapInfoWindow
          :options="{
            width: 0,
            height: -25,
          }"
          style="margin: 0; padding: 0; min-width: 300px"
          :opened="infoWindowStatus"
          :position="currentLatLng ? currentLatLng : { lat: 0, lng: 0 }"
          @closeclick="closeInfoWindow"
        >
          <v-container style="width: 100%; height: 100%; padding: 0">
            <v-card flat light>
              <v-card-text>
                <h3>
                  {{ $t("common.pickLocationConfirm") }}
                </h3>
              </v-card-text>
              <v-card-text>
                <v-btn color="blue darken-1" text @click="updatePos">
                  {{ $t("common.save") }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-container>
        </GmapInfoWindow>
      </GmapMap>
    </v-card>
  </v-dialog>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  name: "PickLocation",

  props: { resource: { default: null } },

  data() {
    return {
      dialog: false,
      map: null,
      tab: null,
      bounds: null,
      infoWindowStatus: false,
      center: { lat: 58, lng: 18 },
      currentLatLng: null,
      confirmDelete: false,
      errorMessage: "",

      tabItems: [
        { label: this.$t("signalTest.tab.map"), key: "map" },
        { label: this.$t("signalTest.tab.data"), key: "data" },
      ],

      markers: [],
    };
  },

  computed: {
    google: gmapApi,
  },

  methods: {
    updatePos() {
      this.$emit(
        "update-pos",
        {
          latitude: this.currentLatLng.lat(),
          longitude: this.currentLatLng.lng(),
        },
        this.resource
      );

      this.dialog = false;
      this.center = { lat: 58, lng: 18 };
      this.currentLatLng = null;
      this.infoWindowStatus = false;
      this.map = null;
      this.markers = [];
    },

    initMap() {
      // Get current location of the user
      this.currentLatLng = new this.google.maps.LatLng(59.334591, 18.06324);

      // Try to get current gps position of user
      navigator.geolocation.getCurrentPosition(
        (success) => {
          this.currentLatLng = new this.google.maps.LatLng(
            success.coords.latitude,
            success.coords.longitude
          );

          this.center = this.currentLatLng;
        },
        (error) => {
          this.errorMessage = error;
        }
      );

      if (this.resource && this.resource.latitude && this.resource.longitude) {
        var pos = new this.google.maps.LatLng(
          this.resource.latitude,
          this.resource.longitude
        );

        this.markers.push({ position: pos, resource: this.resource });
      }
    },

    closeInfoWindow() {
      this.infoWindowStatus = false;
    },

    toggleInfoWindow(pos) {
      this.currentLatLng = pos.latLng;
      this.infoWindowStatus = !this.infoWindowStatus;
    },
  },

  async mounted() {
    await this.$gmapApiPromiseLazy();
  },

  beforeDestroy() {
    this.dialog = false;
    this.center = { lat: 58, lng: 18 };
    this.currentLatLng = null;
    this.infoWindowStatus = false;
    this.map = null;
    this.markers = [];
  },

  watch: {
    dialog(val) {
      if (val)
        this.$nextTick(() => {
          this.initMap();
        });
    },
  },
};
</script>